import React                from 'react'
import { BREAKPOINTS }      from 'utils/constants'
import {
    useApolloClient,
}                           from '@apollo/react-hooks'

import {
    FaAccessibleIcon,
    FaRegEye,
    FaCar,
    FaCheck,
    FaRegCheck,
    // FaClock,
    // FaMapMarker,
    FaRegClock,
    FaRegCommentDots,
    // FaShareAlt,
    // FaTag,
    FaRegSun,
    // FaThumbsUp,
    FaTimes,
    FaRegCheckCircle,
    FaRegTimesCircle,
    FaUtensils,
    FaBus,
    FaShip,
    FaPlane,
    FaShuttleVan,
    FaCamera,
    FaRegMap,
    FaRegFlag,
    FaRegThumbsUp,
    FaMapMarkerAlt,
    FaClock,
    FaTag,
    FaThumbsUp,
} from 'react-icons/fa'

const subsetMapIcons = {
    'far fa-clock': FaRegClock,
    'far fa-eye': FaRegEye,
    'far fa-sun': FaRegSun,
    'far fa-car': FaCar,
    'far fa-check': FaRegCheck,
    'far fa-times': FaTimes,
    'far fa-reg-comment-dots': FaRegCommentDots,
    'far fa-accessible-icon': FaAccessibleIcon,
    'far fa-check-circle': FaRegCheckCircle,
    'far fa-times-circle': FaRegTimesCircle,
    'fas fa-utensils': FaUtensils,
    'fas fa-bus': FaBus,
    'fas fa-ship': FaShip,
    'fas fa-plane': FaPlane,
    'fas fa-shuttle-van': FaShuttleVan,
    'fas fa-camera': FaCamera,
    'far fa-map': FaRegMap,
    'far fa-flag': FaRegFlag,
    'far fa-thumbs-up': FaRegThumbsUp,
    'fas fa-map-marker-alt': FaMapMarkerAlt,
    'fas fa-clock': FaClock,
    'fas fa-tag': FaTag,
    'fas fa-thumbs-up': FaThumbsUp,
    'fas fa-check': FaCheck,
    'fas fa-times': FaTimes,
}

const StringToIcon = ({ icon, ...props}) => {
    const CustomIcon = subsetMapIcons[icon]

    if (!CustomIcon) {
        return `missing ${icon}`
    }

    return <CustomIcon {...props} />
}

const useWindowSize = () => {
    const isClient = typeof window === 'object'

    const getSize = React.useCallback((ev) => {
        const dimensions = {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        }

        return {
            ...dimensions,
            isMobile: isClient ? dimensions.width < BREAKPOINTS.TABLET : false,
            isTablet: isClient ? (dimensions.width >= BREAKPOINTS.TABLET && dimensions.width < BREAKPOINTS.DESKTOP) : false,
            isDesktop: isClient ? dimensions.width >= BREAKPOINTS.DESKTOP : false
        };
    }, [isClient])

    const [windowSize, setWindowSize] = React.useState(getSize)

    React.useEffect(() => {
        if (!isClient) {
            return false
        }

        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [getSize, isClient])

    return windowSize
}

const scrollToTarget = (selector, { offsetTop = 0 }) => {
    const element = typeof window !== 'undefined' ? document.querySelector(selector) : null
    if(element) {
        const bodyRect = document.body.getBoundingClientRect().top
        const elementRect = element.getBoundingClientRect().top
        const elementPosition = elementRect - bodyRect
        const offsetPosition = elementPosition - offsetTop

        window.scrollTo({
             top: offsetPosition,
             behavior: 'smooth'
        })
    }
}

const useLazyQuery = (query) => {
    const client = useApolloClient()

    return React.useCallback((variables) => client.query({
        query: query,
        variables: variables,
    }), [query, client])
}

export {
    StringToIcon,
    useWindowSize,
    scrollToTarget,
    useLazyQuery
}
