import React                from 'react'
import cx                   from 'utils/classnames'
import { map }              from 'lodash-es'
import Helmet               from "react-helmet"
import {
    Container,
}                           from 'react-bootstrap'
import Link                 from 'components/Link'
import Typography           from 'entrada-ui/Typography'
import TourCards            from 'components/TourCards'
import styles               from './styles.module.scss'

const TourCard = TourCards.CardWp

const FourOhFour = ({
    pageContext: {
        data: {
            siteMetadata,
            yoast_meta: {
                yoast_wpseo_social_defaults: {
                  og_default_image,
                  twitter_card_type
                }
              }
        }
    }
}) => {
    return (
        <>
            <Helmet>
                <title>Page not found - AWADS</title>

                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />

                <meta property="og:title" content="Page not found - AWADS" />
                <meta property="og:site_name" content={siteMetadata.title} />
                <meta property="og:image" content={og_default_image} />

                <meta name="twitter:card" content={twitter_card_type} />
                <meta name="twitter:title" content="Page not found - AWADS" />
                <meta name="twitter:image" content={og_default_image} />
            </Helmet>
            <Container>
                <div className={styles.fourOhFourContainer}>
                    <h4>Uh-oh! Nothing here...</h4>
                    <h1>404</h1>
                    <p>Sorry we're not able to find what you were looking for...</p>
                    <a href="/" className="btn btn-primary">Go back home</a>
                </div>
            </Container>
        </>
    )
}

export default FourOhFour
